// Файл с переменными адресов апи

import { create } from 'apisauce';

// Настройки headers и базового урла api-сервера
export const apiWrapper = create({
  baseURL: '/api/v1/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const apiUrls = {
  applicants: {
    vacancies: 'applicants/vacancies_index',
    cities: '/applicants/cities_index',
    citizenships: '/applicants/citizenships_index',
    cinemas: '/applicants/cinemas_index',
    metroStations: '/applicants/metro_stations_index',
    sendConfirmationCode(id) {
      return `/applicants/${id}/confirm`;
    },
    saveApplicant: '/applicants',
    showByAlias: '/applicants/show_by_alias',
  },
};

export const navigationUrls = {
  applicants: {
    formSteps: '/steps',
    main: '/',
    test: '/test',
  },
};
