import React from 'react';

import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps';
import { isMobile } from 'react-device-detect';

import ActivePlacemark from 'icon_map_placemark_active.svg';
import InactivePlacemark from 'icon_map_placemark_inactive.svg';

// Компонент вывода карты с метками - кинотеатрами
export default function CinemasMap({ cinemas, selectedCinema, setCinema }) {
  // Проверка является ли метка активной
  function isActivePlacemark(cinemaId) {
    return Object.keys(selectedCinema) < 1 || cinemaId === selectedCinema.id;
  }

  function renderBalloonContentHeader(name) {
    return (
      `<div>${name}</div>`
    );
  }

  // Вывод города с одним кинотеатром
  function renderSingleCinemaCity() {
    const { longitude, latitude } = cinemas[0];

    return (
      <Map state={{
        center: [longitude, latitude],
        zoom: 10,
      }}
           instanceRef={(ref) => {
             if (ref) {
               if (isMobile) ref.behaviors.disable('drag');
               ref.behaviors.disable('scrollZoom');
             }
           }}
           width="100%"
           height="700px">
        <ZoomControl options={{ float: 'right' }} />
        {renderPlacemark(cinemas[0])}
      </Map>
    );
  }

  // Вывод города с множеством кинотеатров
  function renderMultiCinemasCity() {
    return (
      <Map state={{
        controls: [],
        bounds: cinemas.length > 1 && cinemas.map(item => [item.longitude, item.latitude]),
      }}
           instanceRef={(ref) => {
             if (ref) {
               if (isMobile) ref.behaviors.disable('drag');
               ref.behaviors.disable('scrollZoom');
             }
           }}
           width="100%"
           height="700px">
        <ZoomControl options={{ float: 'right' }} />
        {cinemas.map(cinema => renderPlacemark(cinema))}
      </Map>
    );
  }

  // Вывод метки кинотеатра
  function renderPlacemark({ longitude, latitude, name, id, address }) {
    return (
      <Placemark geometry={[longitude, latitude]}
                 properties={{
                   hintContent: name,
                   balloonContentHeader: renderBalloonContentHeader(name),
                   balloonContentBody: address || 'Скоро тут будет адрес',
                 }}
                 key={`cinema${id}`}
                 onClick={() => setCinema(id)}
                 modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                 options={{
                   iconLayout: 'default#image',
                   iconImageHref: isActivePlacemark(id) ? ActivePlacemark : InactivePlacemark,
                   hideIconOnBalloonOpen: false,
                   balloonCloseButton: false,
                 }} />
    );
  }

  return (
    cinemas.length > 0
    && (
    <YMaps>
      {cinemas.length > 1 ? renderMultiCinemasCity() : renderSingleCinemaCity()}
    </YMaps>
    )
  );
}
