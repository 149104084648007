import React from 'react';

import { NavLink } from 'react-router-dom';

import { navigationUrls } from 'config/routes';

import FinalPageIcon from 'final_page.png';

// Компонент финальной страницы заполнения онлайн-заявки
export default function FinalPage() {
  function renderText() {
    return (
      <>
        <div className="text_center text_transform_uppercase h2 clr_primary mb_5">
          Отлично!
        </div>

        <div className="h4 text_center clr_grey mb_5">
          Уважаемый кандидат! <br />
          Спасибо, что Вы заполнили заявку и проявили интерес к работе <br />
          в КАРО. Надеемся, что мы подойдем друг другу и Вы сможете <br />
          присоединиться к нашей успешной команде! <br />
          Желаем Вам успехов и всегда ждем в наших кинотеатрах в <br />
          качестве гостя!
        </div>

        <div className="h4 text_center clr_primary mb_5">
          С уважением, <br />
          Команда KARO
        </div>
      </>
    );
  }

  // Вывод контента страницы
  function renderMainContent() {
    return (
      <>
        {renderText()}

        <div className="row justify_content_center">
          <div className=" block_md_7 text_center">
            <img alt="icon" className="w_100" src={FinalPageIcon} />
          </div>
        </div>

        <div className="row justify_content_center mt_5">
          <NavLink className="bttn bttn_primary h5"
                   type="button"
                   onClick={() => window.scrollTo(0, 0)}
                   to={navigationUrls.applicants.main}>
            Закрыть
          </NavLink>
        </div>
      </>
    );
  }

  return renderMainContent();
}
