import React from 'react';

import { FixedSizeList as List } from 'react-window';

const height = 35;

// Оптимизация вывода списка селекта
export default function OptimizedSelectList(props) {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      initialScrollOffset={initialOffset}
      itemCount={children.length}
      itemSize={height}>
      {({ index, style }) => <div key={index} style={style}>{children[index]}</div>}
    </List>
  );
}
