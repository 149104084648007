import React from 'react';

import Select from 'react-select';

import OptimizedSelectList from './OptimizedSelectList';

const customStyles = {
  menu: provided => ({
    ...provided,
    zIndex: 100,
  }),
};

// Блок селекта с оптимизированным списком значений
export default function SelectWrapper(props) {
  const {
    label, isMulti = false, options, value, handler, placeholder, styles = '',
    isClearable = false, disabled = false, id,
  } = props;

  return (
    <div className="form_row">
      {label && <label className="form_label" htmlFor={id}>{label}</label>}
      <Select
        className={`${styles}`}
        classNamePrefix="react_select"
        components={{ OptimizedSelectList }}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={isMulti}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        value={value}
        required
        onChange={handler} />
    </div>
  );
}
