import React, { useState, useRef } from 'react';

import { NavLink } from 'react-router-dom';

import { navigationUrls } from 'config/routes.js';

import testFinal from 'test/step_final.png';

import LayoutWrapper from 'components/helpers/LayoutWrapper';
import MainContentWrapper from 'components/helpers/MainContentWrapper';
import TestQuestionPage from 'components/testPage/TestQuestionPage';

import { useDispatch } from 'react-redux';
import { setTestIsPassed } from 'redux/actions/testData';

export default function TestPage() {
  const testRef = useRef();

  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  // Функция перехода на следующий вопрос / завершение теста
  function setNextQuestion() {
    if (page === 3) dispatch(setTestIsPassed());
    setPage(page + 1);
  }

  // Функция рендера финальной страницы теста
  function renderTestFinalPage() {
    return (
      <div className="panel_block">
        <div className="d_flex direction_column align_items_center">
          <p className="h2 text_center p_4">
            Ты определенно любишь похрустеть попкорном перед огромным экраном в кинозале,
            и видимо, неплохо разбираешься в кино :)
          </p>

          <p className="h1 clr_primary text_transform_uppercase text_center text_bold p_4">
            Присоединяйся к нашей команде КАРО&nbsp;!
          </p>

          <img alt="империя кино" className="test_image_final p_4" src={testFinal} />
          <NavLink className="bttn bttn_primary bttn_wide w_100 h5 mb_5 ml_0 clr_light text_center pl_0 pr_0"
                   onClick={() => window.scrollTo(0, 0)}
                   to={navigationUrls.applicants.formSteps}>Заполни заявку
          </NavLink>
        </div>
      </div>
    );
  }

  // Рендер страницы c тестом
  function renderTestPage() {
    return (
      <LayoutWrapper>
        <MainContentWrapper>
          <p ref={testRef} className="text_transform_uppercase text_center h1 clr_light pb_5">
            <span className="clr_primary">Тест</span>
            &nbsp;на сколько тебе подходит работа в КАРО
          </p>

          {page < 4 ? (
            <TestQuestionPage page={page} setNextQuestion={setNextQuestion} ref={testRef} />
          ) : (
            renderTestFinalPage()
          )}
        </MainContentWrapper>
      </LayoutWrapper>
    );
  }

  return renderTestPage();
}
