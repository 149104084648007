import React, { useState, forwardRef } from 'react';

import { NavLink } from 'react-router-dom';

import { navigationUrls } from 'config/routes.js';

import testPage1 from 'test/step_1.png';
import testPage2 from 'test/step_2.png';
import testPage3 from 'test/step_3.png';

import HeaderInfo from 'components/formSteps/HeaderInfo';

const testQuestions = [
  {
    id: 1,
    question: 'Кто твой любимый режиссер?',
    picture: testPage1,
    answers: ['Стивен Спилберг', 'Питер Джексон', 'Мартин Скорсезе', 'Кристофер Нолан', 'Другой'],
    button1Text: 'Назад',
    button2Text: 'Следующий вопрос',
  },
  {
    id: 2,
    question: 'Какой попкорн твой любимый?',
    picture: testPage2,
    answers: ['Солёный', 'Сладкий', 'Сырный', 'Шоколадный', 'Другой'],
    button1Text: 'Закрыть тест',
    button2Text: 'Следующий вопрос',
  },
  {
    id: 3,
    question: 'Какой твой любимый жанр кино?',
    picture: testPage3,
    answers: ['Комедия', 'Драма', 'Триллер', 'Ужасы', 'Другой'],
    button1Text: 'Закрыть тест',
    button2Text: 'Завершить тест',
  },
];
// Компонент рендера одной страницы теста с вопросом и ответами
const TestQuestionPage = forwardRef(({ page, setNextQuestion }, ref) => {
  const [selectedItems, setSelectedItems] = useState([]);

  // Обработка выбора ответа
  function handleSelectingAnswer(item) {
    const newSelectedItems = [...selectedItems];

    // Если массив выбранных ответов уже содержит выбранный ответ, то удаляем его
    if (newSelectedItems.includes(item)) {
      newSelectedItems.splice(newSelectedItems.findIndex(i => i === item), 1);

    // Если массив выбранных ответов не содержит выбранный ответ, добавляем его в массив
    } else {
      newSelectedItems.push(item);
    }

    setSelectedItems(newSelectedItems);
  }

  // Переход на следующий вопрос теста
  function finishQuestion() {
    setSelectedItems([]);
    setNextQuestion();

    ref.current.scrollIntoView();
  }

  // Функция рендера блока с ответами
  function renderAnswers() {
    return (
      <div className="w_100 block_md_5 mt_auto mb_auto">
        {testQuestions[page - 1].answers.map((item, idx) => (
          <div className="w_100 form_check d_flex align_items_center pt_3 pb_3"
               key={item + page}
               onClick={() => { handleSelectingAnswer(item); }}>
            <input checked={selectedItems.includes(item)}
                   id={idx}
                   name={page}
                   readOnly
                   type="checkbox" />
            <div className="viewed_input d_flex"><span className="m_auto" /></div>
            <span className="h4 cursor_pointer" htmlFor={idx}>
              {item}
            </span>
          </div>
        ))}
      </div>
    );
  }

  // Функция рендера блока с кнопками перехода между шагами прохождения теста
  function renderTestButtonsWrapper() {
    return (
      <div className="d_flex justify_content_between flex_wrap_wrap mt_5 mb_4 w_100">
        <NavLink className="bttn bttn_primary bttn_wide h5 mb_3 clr_light text_center"
                 to={navigationUrls.applicants.formSteps}>Закрыть тест
        </NavLink>

        <button className={`bttn bttn_${selectedItems.length === 0 ? 'disabled' : 'primary'}
                           bttn_wide h5 mb_3`}
                disabled={selectedItems.length === 0}
                type="button"
                onClick={finishQuestion}>
          {(page === 3) ? 'Завершить тест' : 'Следующий вопрос'}
        </button>
      </div>
    );
  }

  // Функция рендера страниц теста
  function renderTest() {
    return (
      <div className="panel_block pt_5 pb_5">
        <HeaderInfo isTest step={page} label={testQuestions[page - 1].question} />

        <div className="row">
          <img alt="стул" className="test_image block_md_7 pt_4" src={testQuestions[page - 1].picture} />
          {renderAnswers()}
        </div>
        {renderTestButtonsWrapper()}
      </div>
    );
  }

  return renderTest();
});

export default TestQuestionPage;
