import React, { useState, forwardRef } from 'react';

import validator from 'validator';

import StepsButtonsWrapper from 'components/formSteps/ButtonsWrapper';
import StepsHeaderInfo from 'components/formSteps/HeaderInfo';
import FormInputWrapper from 'components/helpers/FormInputWrapper';

import { emailValidation, requiredValidation } from 'functions/Validations';

import { useDispatch, useSelector } from 'react-redux';

import { updateApplicantFormData } from 'redux/actions/applicantData';

import IconClose from 'icon_close.svg';

// Компонент вывода пятого шага заполнения онлайн-заявки
const StepFive = forwardRef(({ step, nextStep, prevStep }, ref) => {
  const dispatch = useDispatch();
  const applicantFormData = useSelector(state => state.applicantFormData);

  const [email, setEmail] = useState(applicantFormData.email || '');
  const [phoneNumber, setPhoneNumber] = useState(applicantFormData.phoneNumber || '');
  const [agreement, setAgree] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  // Сохранение email и номера телефона пользователя
  function updateReduxApplicantData(e) {
    e.preventDefault();
    e.stopPropagation();

    dispatch(updateApplicantFormData({ email, phoneNumber }));
    nextStep();
  }

  // Проверка блокировки кнопки перехода на следующий этап
  function isSaveButtonDisabled() {
    return !validator.isEmail(email) || validator.isEmpty(email) || validator.isEmpty(phoneNumber) || !agreement;
  }

  // Вывод чекбокса и текста согласия на обработку персональных данных
  function renderAgreement() {
    return (
      <div aria-hidden="true"
           className="form_check d_flex align_items_center mb_3">
        <input checked={agreement}
               type="checkbox"
               readOnly />
        <div className="viewed_input" onClick={() => setAgree(!agreement)}><span /></div>

        <span className="h6 m_0 clr_dark" style={{ maxWidth: '90%' }}>
          Нажимая кнопку «Отправить заявку», я подтверждаю свою дееспособность, даю<br />
          согласие на обработку своих персональных данных ООО «КАРО Фильм Менеджмент»<br />
          на&nbsp;
          <span className="clr_primary cursor_pointer text_under"
                onClick={() => setShowTerms(!showTerms)}>
            следующих условиях
          </span>
        </span>
      </div>
    );
  }

  // Вывод обертки формы заполнения контактных данных
  function renderFormWrapper() {
    return (
      <div className="row justify_content_center">
        <div className="block_md_7">
          <FormInputWrapper className="mb_4"
                            htmlFor="firstName"
                            mask="+7 (999) 999-99-99"
                            placeholder="Телефон *"
                            setValue={setPhoneNumber}
                            validations={[requiredValidation]}
                            value={phoneNumber} />

          <FormInputWrapper className="mb_4"
                            htmlFor="secondName"
                            placeholder="E-mail *"
                            setValue={setEmail}
                            validations={[requiredValidation, emailValidation]}
                            value={email} />

          {renderAgreement()}
        </div>
      </div>
    );
  }

  // Вывод модалки текста согласия на обработку персональных данных
  function renderShowTerms() {
    return (
      showTerms && (
        <div className="modal_wrapper">
          <div className="panel_block with_shadow pt_5">
            <div aria-hidden="true"
                 className="bttn_close"
                 onClick={() => setShowTerms(false)}>
              <img alt="icon" src={IconClose} />
            </div>

            <div className="clr_grey text_transform_uppercase mb_5">
              Согласие кандидата на обработку <br /> персональных данных
            </div>

            {renderTermsText()}
          </div>
        </div>
      )
    );
  }

  // Вывод текста согласия на обработку данных
  function renderTermsText() {
    return (
      <div className="h5 clr_grey">
        Я своей волей и в своем интересе даю согласие на обработку обществом с ограниченной ответственностью
        «КАРО Фильм Менеджмент» (119019, г. Москва, ул. Новый Арбат, д. 24) (далее – «Компания КАРО»)
        моих персональных данных, указанных в форме на сайте www.hr.karofilm.ru и / или в приложенном
        резюме, а также на передачу Компанией КАРО моих персональных данных третьим лицам, привлекаемым
        Компанией КАРО для целей, связанных с моим трудоустройством.
        Цель обработки персональных данных:
        - осуществление Компанией КАРО любых контактов со мной по вопросам предложения вакансий,
        дальнейшего трудоустройства;
        - проведение собеседований и принятие решения о приеме либо отказе в приеме меня на работу в Компанию КАРО;
        - включение меня в кадровый резерв Компании КАРО.
        Перечень персональных данных, подлежащих обработке, указан в заполняемой мной форме на
        сайте www.hr.karofilm.ru и/или в приложенном резюме, и в том числе содержит следующие данные:
        фамилия, имя, возраст, гражданство, номер мобильного телефона, адрес электронной почты.
        Способы обработки персональных данных: сбор, запись, систематизация, накопление, хранение,
        уточнение (обновление, изменение), извлечение, использование, передача (распространение,
        предоставление, доступ), блокирование, обезличивание, удаление и уничтожение персональных данных,
        совершение иных действий (операций) с персональными данными, совершаемых с использованием средств
        автоматизации и без использования таких средств (неавтоматизированная обработка).
        Настоящим заверяю и гарантирую, что (i) данные, указанные в форме на сайте www.hr.karofilm.ru
        и/или в приложенном резюме являются достоверными, (ii) я являюсь законным пользователем (абонентом)
        адреса электронной почты и номера мобильного телефона, которые указаны мной в форме на
        сайте www.hr.karofilm.ru, и обязуюсь возместить компании КАРО любой ущерб, причиненный в
        связи с недостоверностью указанных мной данных и/или нарушением предоставленных заверений и гарантий.
        Настоящее согласие действует в течение пяти лет со дня его предоставления.
        Я уведомлен и согласен(-а) с тем, что вправе отозвать согласие на обработку персональных
        данных путем направления письменного обращения к Компании КАРО по адресу места нахождения:
        119019, г. Москва, ул. Новый Арбат, д. 24 либо по адресу электронной почты: hr@karofilm.ru
        (указав тему письма «КАРО СТОП»).
      </div>
    );
  }

  // Вывод контента страницы
  function renderMainContent() {
    return (
      <>
        <StepsHeaderInfo description="Укажи свои контактные данные, чтобы мы смогли связаться с тобой"
                         label="Контактные данные"
                         step={step} />

        {renderFormWrapper()}

        <StepsButtonsWrapper isSaveButtonDisabled={isSaveButtonDisabled()}
                             nextAction={updateReduxApplicantData}
                             prevAction={prevStep}
                             ref={ref} />

        {renderShowTerms()}
      </>
    );
  }

  return renderMainContent();
});

export default StepFive;
