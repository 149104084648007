import React, { useState } from 'react';

import ReactCardFlip from 'react-card-flip';
import moment from 'moment';

import carrierGrowth from 'main_cards/carrier_growth.svg';
import salary from 'main_cards/salary.svg';
import schedule from 'main_cards/schedule.svg';
import pointOnMap from 'main_cards/point_on_map.svg';
import employmentContract from 'main_cards/employment_contract.svg';
import logoWhitePath from 'logo_white.svg';
import superjobPath from 'best_employer2018.png';

// Компонент основной оболочки
export default function LayoutWrapper({ children }) {
  const [isFlipped, setIsFlipped] = useState(null);

  const cards = [
    {
      id: 2,
      image: carrierGrowth,
      textFront: 'Карьерный рост и перспективы',
      textBack: 'Мы ценим наших сотрудников и предоставляем обучение, обеспечивающее'
        + ' профессиональный и личностный рост. Расти вместе с нами!',
    },
    {
      id: 3,
      image: salary,
      textFront: 'Конкурентная заработная плата',
      textBack: 'Предоставляемый компенсационный пакет позволяет быть уверенным в завтрашнем дне и в стабильности!',
    },
    {
      id: 4,
      image: schedule,
      textFront: 'Гибкий график',
      textBack: 'Выбирай удобное для тебя время и легко совмещай работу со своими интересами.'
        + ' Настройся на удобный для тебя ритм, а мы с радостью поддержим тебя в этом.',
    },
    {
      id: 5,
      image: pointOnMap,
      textFront: 'Выбери свой кинотеатр',
      textBack: '35 кинотеатров в России, и их количество становится больше!',
    },
    {
      id: 6,
      image: employmentContract,
      textFront: 'Официальное трудоустройство',
      textBack: 'Отношения с работодателем оформляются в рамках действующего законодательства.',
    },
  ];

  // Отрисовка Header
  function renderHeader() {
    return (
      <div className="header">
        <div className="container h_100">
          <div className="d_flex justify_content_between align_items_center h_100 clr_light">
            <div className="d_flex align_items_center">
              <a href="https://karofilm.ru">
                <img alt="каро" src={logoWhitePath} className="white_logo" />
              </a>
              <a className="header_link h6 ml_3 mb_0" href="https://karofilm.ru/theaters">Кинотеатры</a>
            </div>
            <img alt="Привлекательный работодатель" src={superjobPath} />
          </div>
        </div>
      </div>
    );
  }

  // Отрисовка текста приветствия
  function renderInfo() {
    return (
      <div className="info_main_block d_flex direction_column text_center pt_5 pb_5 ">
        <p>
          <span className="h1 clr_primary">&laquo;КАРО&raquo;</span>
          - это динамичная, современная, прогрессивная сеть кинотеатров,
          созданная в июле 1997 года и представленная 35 кинотеатрами, которая входит
          в тройку лидеров в кинотеатральном бизнесе России.
        </p>
        <p className="clr_primary p_3">Присоединяйся к нашей команде!</p>
      </div>
    );
  }

  // Отрисовка сетки с карточками
  function renderCards() {
    return (
      <div className="cards d_grid">
        <div className="card card_red m_auto">
          <p className="card_red_text h1 text_transform_uppercase clr_light">Работа в&nbsp;Каро -&nbsp;это </p>
        </div>

        {cards.map(({ id, image, textFront, textBack }) => (
          <ReactCardFlip flipDirection="horizontal" isFlipped={isFlipped === id} key={id}>
            <div className="card d_flex direction_column justify_content_between align_items_center"
                 onMouseEnter={() => setIsFlipped(id)}>
              <img alt="пиктограмма" className="card_image" src={image} />

              <div className="card_text h5 d_flex direction_column align_items_center justify_content_between">
                <p className="text_center clr_primary mt_2">{ textFront }</p>
                <div className="text_underline clr_grey h6 mb_2 b_0"
                     onClick={() => setIsFlipped(id)}>подробнее →
                </div>
              </div>
            </div>

            <button className="card card_back d_flex b_0"
                    type="button"
                    onClick={() => setIsFlipped(null)}
                    onMouseLeave={() => setIsFlipped(null)}>
              <p className="m_auto h5">{ textBack }</p>
            </button>
          </ReactCardFlip>
        ))}
      </div>
    );
  }

  // Отрисовка Footer
  function renderFooter() {
    return (
      <div className="footer">
        <div className="container">
          <div className="inner d_flex justify_content_between align_items_center clr_light pt_5 pb_5">
            <a href="https://karofilm.ru">
              <img alt="каро" className="pb_4 pr_3" src={logoWhitePath} />
            </a>

            <div className="w_100">
              <div className="footer_links d_flex justify_content_between h6 p_3">
                <a href="https://karofilm.ru/theaters">Кинотеатры</a>
                <a href="https://karofilm.ru/news">Новости</a>
                <a href="https://hr.karofilm.ru">Вакансии</a>
                <a href="https://karofilm.ru/site/contacts">Контакты</a>
              </div>

              <div className="footer_bottom bt_1">
                <p className="h6 mt_2">
                  Все права защищены
                  <br />
                  &copy; 2007 -
                  { moment().format('YYYY') }
                  &nbsp;&laquo;КАРО Фильм Менеджмент&raquo;
                </p>
              </div>

              <div className="d_flex justify_content_end mt_3">
                <a className="icon icon_tw" href="https://twitter.com/karo_film">twitter</a>
                <a className="icon icon_ok" href="https://ok.ru/karofilm">одноклассники</a>
                <a className="icon icon_vk" href="https://vk.com/karofilm_vk">в контакте</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Рендер контента
  function renderMainContent() {
    return (
      <>
        { renderHeader() }
        { children }
        <div className="container">
          { renderInfo() }
          { renderCards() }
        </div>
        { renderFooter() }
      </>
    );
  }

  return renderMainContent();
}
