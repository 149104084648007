import React, { useState } from 'react';

import InputMask from 'react-input-mask';

// Обертка для полей ввода текста
const FormInputWrapper = ({
  htmlFor, value = '', placeholder = '', setValue, className = '', label,
  type = 'input', validations = [], mask,
}) => {
  const [errors, setErrors] = useState({});

  // Функция сохранения введенных данных с проверкой по переданным валидациям
  function onChange(changedValue) {
    setErrors({});

    validations.forEach((validation) => {
      if (validation(changedValue)) setErrors({ [htmlFor]: validation(changedValue) });
    });

    setValue(changedValue);
  }

  // Вывод инпута в зависимости от переданной маски
  function renderInput() {
    return (
      mask ? (
        <InputMask className={`form_input ${errors[htmlFor] ? 'error' : ''}`}
                   mask={mask}
                   name={htmlFor}
                   placeholder={placeholder}
                   value={value}
                   onChange={e => onChange(e.target.value)} />
      ) : (
        <input className={`form_input ${errors[htmlFor] ? 'error' : ''}`}
               name={htmlFor}
               placeholder={placeholder}
               type="text"
               value={value}
               onChange={e => onChange(e.target.value)} />
      )
    );
  }

  // Вывод обертки поля ввода
  function renderInputWrapper() {
    return (
      <div className={`${className}`}>
        {label && <label className="clr_grey" htmlFor={htmlFor}>{label}</label>}

        <div className="d_flex mt_2">
          {type === 'input' ? (
            renderInput()
          ) : (
            <textarea className={`form_input ${errors[htmlFor] ? 'error' : ''}`}
                      name={htmlFor}
                      rows="8"
                      value={value}
                      onChange={e => onChange(e.target.value)} />
          )}
        </div>

        {errors[htmlFor] && errors[htmlFor]}
      </div>
    );
  }

  return renderInputWrapper();
};

export default FormInputWrapper;
