export const enumList = {
  steps: [
    {
      step: 1, progress: '0%', afterProgress: '10%',
    },
    {
      step: 2, progress: '10%', afterProgress: '50%',
    },
    {
      step: 3, progress: '50%', afterProgress: '60%',
    },
    {
      step: 4, progress: '60%', afterProgress: '70%',
    },
    {
      step: 5, progress: '70%', afterProgress: '100%',
    },
  ],
};

// Возвращает из выбранного списка выбранный объект
export function findEnumItem(enumListItem, step) {
  return enumList[enumListItem].find(o => o.step === step);
}
