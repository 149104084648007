import { createStore } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import appReducer from 'redux/reducers/applicantData';
import testReducer from 'redux/reducers/testData';

const persistConfig = {
  key: 'root',
  storage,
};

const pReducer = persistCombineReducers(persistConfig, { applicantFormData: appReducer, testData: testReducer });

export const store = createStore(pReducer);
export const persistor = persistStore(store);
