import React, { useRef, useState, useEffect } from 'react';

import { NavLink, useParams } from 'react-router-dom';
import { isBrowser, isMobile } from 'react-device-detect';

import { useSelector } from 'react-redux';

import { navigationUrls } from 'config/routes.js';

import testBanner from 'test/banner.png';

import StepOne from 'views/formSteps/StepOne';
import StepTwo from 'views/formSteps/StepTwo';
import StepThree from 'views/formSteps/StepThree';
import StepFour from 'views/formSteps/StepFour';
import StepFive from 'views/formSteps/StepFive';
import SmsConfirmation from 'views/formSteps/SmsConfirmation';
import FinalPage from 'views/formSteps/FinalPage';

import LayoutWrapper from 'components/helpers/LayoutWrapper';
import LoaderThrobber from 'components/helpers/LoaderThrobber';
import MainContentWrapper from 'components/helpers/MainContentWrapper';

export default function ApplicantFormPage() {
  const { alias } = useParams();
  const formRef = useRef(null);

  const [step, setStep] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const components = {
    1: StepOne,
    2: StepTwo,
    3: StepThree,
    4: StepFour,
    5: StepFive,
    6: SmsConfirmation,
    7: FinalPage,
  };

  const CurrentStep = components[step];
  const isTestPassed = useSelector(state => state.testData.isPassed);

  useEffect(() => {
    if (alias) setStep(2);
  }, [alias]);

  // Рендер приглашения пройти тест
  function renderTestInvitation() {
    return (
      <div className="panel_block test">
        <div className="row">
          <div className={`block_lg_6 ${isMobile && 'd_flex align_items_center'}`}>
            <img className="test_image w_100" src={testBanner} alt="попкорн и кола" />
          </div>
          <div className="block_lg_6">
            <p className={`text_transform_uppercase text_bold pb_${isMobile ? '2 text_center h2' : '5 h1'}`}>
              Узнай насколько тебе подходит работа в Каро
            </p>
            <NavLink className={`bttn bttn_primary bttn_wide h5 b_0 border_r_20
                      text_center box_sizing_border ${isBrowser && 'mb_5'}`}
                     to={navigationUrls.applicants.test}>Пройти тест
            </NavLink>
          </div>
        </div>
      </div>
    );
  }

  return (
    <LayoutWrapper>
      <MainContentWrapper>
        { !isTestPassed && renderTestInvitation() }
        <p ref={formRef} className={`text_transform_uppercase text_center clr_light mb_${isMobile ? '3 h3' : '5 h2'}`}>
          Заполни&nbsp;
          <span className="clr_primary">онлайн заявку</span>
          <br />
          прямо сейчас
        </p>

        <div className={`panel_block pb_5 pt_${isMobile ? '3' : '5'}`}>
          <CurrentStep isLoading={isLoading}
                       nextStep={() => setStep(step + 1)}
                       prevStep={() => setStep(step - 1)}
                       setIsLoading={setIsLoading}
                       step={step}
                       alias={alias}
                       ref={formRef} />

          {isLoading && <LoaderThrobber lighter />}
        </div>
      </MainContentWrapper>
    </LayoutWrapper>
  );
}
