import moment from 'moment';

let timerInterval = null;
let timerTimeout = null;

// Показываем сформатированное переданное оставшееся время
export function showTimeLeft(time, format = 'mm:ss') {
  const timeLeft = time < 0 ? 0 : time;
  const timerDuration = moment.duration(timeLeft, 'seconds');

  return moment(timerDuration.asMilliseconds()).format(format);
}

// Запуск обновления таймера с заданной периодичностью
// setTimer - обновление таймера в стейте компонента, где была вызвана функция
export const startInterval = (timer, setTimer, periodicity = 1000) => {
  let newTimer = timer;

  timerInterval = setInterval(() => {
    newTimer -= 1;
    setTimer(newTimer);
  }, periodicity);
};

// Задержка автоматического выполнения функции, переданной в action
export const startTimeout = (timer, action, withInterval = false) => {
  clearTimeout(timerTimeout);

  timerTimeout = setTimeout(() => {
    action();
    if (withInterval) { clearInterval(timerInterval); }
  }, timer * 1000 + 1000);
};

// Сброс таймера
export const stopInterval = () => {
  clearInterval(timerInterval);
};

// Сброс таймаута
export const stopTimeout = () => {
  clearTimeout(timerTimeout);
};
