import { handleActions } from 'redux-actions';

const initialState = {};

export default handleActions({
  UPDATE(state, action) {
    return { ...state, ...action.payload };
  },
  REMOVE() {
    return initialState;
  },
}, initialState);
