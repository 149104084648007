import React, { useEffect, useState, forwardRef } from 'react';

import validator from 'validator';

import SelectWrapper from 'components/helpers/SelectWrapper';
import StepsButtonsWrapper from 'components/formSteps/ButtonsWrapper';
import StepsHeaderInfo from 'components/formSteps/HeaderInfo';
import FormInputWrapper from 'components/helpers/FormInputWrapper';

import { requiredValidation } from 'functions/Validations';
import { convertArrayToSelectView } from 'functions/Data';

import { useDispatch, useSelector } from 'react-redux';
import { updateApplicantFormData } from 'redux/actions/applicantData';

import { apiUrls, apiWrapper } from 'config/routes';

// Компонент вывода второго шага заполнения онлайн-заявки
const StepTwo = forwardRef(({ step, nextStep, prevStep, alias, setIsLoading }, ref) => {
  const dispatch = useDispatch();
  const applicantFormData = useSelector(state => state.applicantFormData);

  const [ages, setAges] = useState([]);

  const [age, setAge] = useState(applicantFormData.age || '');
  const [firstName, setFirstName] = useState(applicantFormData.firstName || '');
  const [secondName, setSecondName] = useState(applicantFormData.secondName || '');

  useEffect(() => {
    if (alias) {
      setIsLoading(true);
      fetchCinema().then(() => setIsLoading(false));
    }
  }, [alias]);

  useEffect(() => {
    const array = [];

    for (let i = 18; i < 66; i++) array.push({ value: i, label: i });

    setAges(array);
  }, []);

  // Получение данных кинотеатра, если в урле есть alias
  async function fetchCinema() {
    const response = await apiWrapper.get(apiUrls.applicants.showByAlias, { alias });

    if (response.ok) {
      const { cinema: { city, id, name } } = response.data;

      dispatch(updateApplicantFormData({ cinema: convertArrayToSelectView([{ id, name }])[0], city }));
    }
  }

  // Сохранение имени, фамилии и возраста пользователя
  function updateReduxApplicantData(e) {
    e.preventDefault();
    e.stopPropagation();

    dispatch(updateApplicantFormData({
      age, firstName, secondName,
    }));
    nextStep();
  }

  // Проверка блокировки кнопки перехода на следующий этап
  function isSaveButtonDisabled() {
    return Object.keys(age).length < 1 || validator.isEmpty(firstName) || validator.isEmpty(secondName);
  }

  // Вывод обертки формы заполнения личных данных
  function renderFormWrapper() {
    return (
      <div className="row justify_content_center">
        <div className="block_md_7">
          <FormInputWrapper className="mb_4"
                            htmlFor="firstName"
                            placeholder="Фамилия *"
                            setValue={setFirstName}
                            validations={[requiredValidation]}
                            value={firstName} />

          <FormInputWrapper className="mb_4"
                            htmlFor="secondName"
                            placeholder="Имя *"
                            setValue={setSecondName}
                            validations={[requiredValidation]}
                            value={secondName} />

          <SelectWrapper handler={option => setAge(option)}
                         options={ages}
                         placeholder={(
                           <div className="row clr_grey">
                             Возраст&nbsp;<p className="clr_primary">*</p>
                           </div>
                         )}
                         value={age} />
        </div>
      </div>
    );
  }

  // Вывод контента страницы
  function renderMainContent() {
    return (
      <>
        <StepsHeaderInfo description="Заполни личную информацию в форме ниже"
                         label="Личная информация"
                         step={step} />

        {renderFormWrapper()}

        <StepsButtonsWrapper isSaveButtonDisabled={isSaveButtonDisabled()}
                             nextAction={updateReduxApplicantData}
                             prevAction={prevStep}
                             ref={ref} />
      </>
    );
  }

  return renderMainContent();
});

export default StepTwo;
