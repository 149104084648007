import React, { useEffect, useState, forwardRef } from 'react';

import InputMask from 'react-input-mask';
import validator from 'validator';
import moment from 'moment';
import ReactGA from 'react-ga';

import StepsButtonsWrapper from 'components/formSteps/ButtonsWrapper';

import { startTimeout, startInterval, showTimeLeft, stopInterval } from 'functions/Time';

import { useDispatch, useSelector } from 'react-redux';
import { updateApplicantFormData, removeApplicantFormData } from 'redux/actions/applicantData';

import { apiUrls, apiWrapper } from 'config/routes';

// Компонент вывода четвертого шага заполнения онлайн-заявки
const SmsConfirmation = forwardRef(({ nextStep, prevStep }, ref) => {
  const dispatch = useDispatch();
  const applicantFormData = useSelector(state => state.applicantFormData);

  const [confirmationCode, setConfirmationCode] = useState('');
  const [timer, setTimer] = useState(null);
  const [applicantId, setApplicantId] = useState(null);

  const [errors, setErrors] = useState({});

  const formData = new FormData();

  const timerDuration = 60;

  useEffect(() => {
    saveApplicant();

    ReactGA.initialize('UA-50718706-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // Функция запроса на сохранение заявки и отправку кода подтверждения
  async function saveApplicant() {
    const response = await apiWrapper.post(apiUrls.applicants.saveApplicant, buildFormData());

    if (response.ok) {
      dispatch(updateApplicantFormData({ timerEndTime: moment().add(timerDuration, 'seconds') }));
      setApplicantId(response.data.applicant.id);
    } else {
      const { applicant: { errors: responseErrors } = {}, sms_log: smsLog } = response.data;
      setErrors(responseErrors || smsLog);
    }

    setTimer(timerDuration);
    startTimeout(timerDuration, () => {
      stopInterval();
      setTimer(null);
    }, true);
    startInterval(timerDuration, time => setTimer(time));
  }

  // Запрос на проверку кода из смс
  async function sendConfirmationCode() {
    const response = await apiWrapper.get(apiUrls.applicants.sendConfirmationCode(applicantId), { confirmationCode });

    if (response.ok) {
      const { firstName, secondName } = applicantFormData;

      ReactGA.event({
        category: 'KaroHR_applicant',
        action: 'Добавлена новая анкета',
        label: `${firstName} ${secondName}`,
      });
      dispatch(removeApplicantFormData());
      nextStep();
    } else setErrors(response.data.errors);
  }

  // Формирование данных для отправки на сервер
  function buildFormData() {
    const {
      firstName, secondName, citizenship, about, age,
      schedules, email, phoneNumber, cinema,
    } = applicantFormData;

    let schedulesString = '';

    schedules.forEach((item) => {
      schedulesString = `${schedulesString} ${item.name}`;
    });

    formData.append('applicant[firstName]', firstName);
    formData.append('applicant[lastName]', secondName);
    formData.append('applicant[name]', `${firstName} ${secondName}`);
    formData.append('applicant[age]', age.label);
    formData.append('applicant[phone]', phoneNumber.match(/\d+/g).join('').substring(1));
    formData.append('applicant[email]', email);
    formData.append('applicant[cinemaId]', cinema.value);
    formData.append('applicant[citizenshipId]', citizenship.value);
    formData.append('applicant[info]', about);
    formData.append('applicant[schedule]', schedulesString);

    return formData;
  }

  // Проверка блокировки кнопки подтверждения заявки по СМС
  function isSaveButtonDisabled() {
    let code = confirmationCode.toString().match(/\d+/g) || [];
    code = code.join('');

    return validator.isEmpty(confirmationCode) || code.length < 6;
  }

  // Вывод текста блока
  function renderText() {
    return (
      <>
        <div className="text_center text_transform_uppercase h2 clr_primary mb_5">
          Подтвердите отправку заявки
        </div>

        <div className="text_center clr_grey mb_5">
          Мы выслали смс-сообщение с кодом <br />
          подтверждения на указанный вами номер <br />
          телефона: {applicantFormData.phoneNumber} <br />
          Внесите его в поле ниже, нажмите кнопку <br />
          «Подтвердить» и ваша заявки будет отправлена
        </div>
      </>
    );
  }

  // Вывод обертки заполнения кода из смс
  function renderFormWrapper() {
    return (
      <div className="row direction_column align_items_center">
        {timer ? (
          <span className="row h6 clr_grey">
            Отправить код повторно через&nbsp;
            <p className="clr_primary">{showTimeLeft(timer, 'm:ss')}</p>
          </span>
        ) : (
          <span className="h6 clr_grey clr_primary cursor_pointer" onClick={saveApplicant}>
            Отправить код повторно
          </span>
        )}

        <div className="block_md_4 mt_2">
          <div className="d_flex">
            <InputMask className={`form_input text_center ${Object.keys(errors).length > 0 ? 'error' : ''} `}
                       mask="999999"
                       name="sms_confirmation"
                       placeholder="Код подтверждения из СМС"
                       value={confirmationCode}
                       onChange={e => setConfirmationCode(e.target.value)} />
          </div>

          {errors && Object.values(errors).flatMap((item, index) => (
            <div key={index} className="h6 clr_primary mt_1">{item}</div>
          ))}
        </div>
      </div>
    );
  }

  // Вывод контента страницы
  function renderMainContent() {
    return (
      <>
        {renderText()}

        {renderFormWrapper()}

        <StepsButtonsWrapper isSaveButtonDisabled={isSaveButtonDisabled()}
                             nextAction={sendConfirmationCode}
                             prevAction={prevStep}
                             ref={ref} />
      </>
    );
  }

  return renderMainContent();
});

export default SmsConfirmation;
