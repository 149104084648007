import { apiWrapper } from '../config/routes';

// Конвертируем входящий массив в подходящий массив для вывода в селекте
export function convertArrayToSelectView(list) {
  return list.map((item) => {
    const { id, name = null } = item;

    return { ...item, ...{ value: id, label: name } };
  });
}

// Получаем список всех элементов для селекта
export async function fetchListItemsForSelect(url, elements, params = {}) {
  const response = await apiWrapper.get(url, params);

  if (response.ok) {
    return convertArrayToSelectView(response.data[elements]);
  }
  return null;
}
