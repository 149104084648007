import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ApplicantFormPage from 'views/ApplicantFormPage';
import HomePage from 'views/HomePage';
import TestPage from 'views/TestPage';

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/steps" component={ApplicantFormPage} />
          <Route exact path="/test" component={TestPage} />
          <Route exact path="/anketa/:alias" component={ApplicantFormPage} />
        </Switch>
      </div>
    </Router>
  );
}
