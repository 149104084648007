// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

import React from 'react';

import * as ReactDOM from 'react-dom';

import App from 'components/main/App';

// Импорт и установка дефолтной русской локали плагина времени
// import moment from 'moment'
// var configLocale = require('moment/locale/ru')
// moment.updateLocale('ru', configLocale)

// Записываем в глобальные переменные плагин времени
// и переменные темы оформления для постоянного к ним доступа
// global.moment = moment

// Support component names relative to this directory:
// var componentRequireContext = require.context('components', true);
// var ReactRailsUJS = require('react_ujs');
// ReactRailsUJS.useContext(componentRequireContext);

ReactDOM.render(<App />, document.getElementById('app_entrypoint'));
