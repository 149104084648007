import React, { forwardRef } from 'react';

// Вывод кнопок перехода между шагами заполнения формы
const StepsButtonsWrapper = forwardRef((props, ref) => {
  const { isSaveButtonDisabled, nextAction, prevAction, prevButtonVisible = true, confirmation = false } = props;

  // Вызов функции и скролл к верху формы
  function onClick(e, action) {
    if (ref) ref.current.scrollIntoView();
    action(e);
  }

  return (
    <div className={`row justify_content_${prevButtonVisible ? 'between' : 'end'} mt_5`}>
      {prevButtonVisible && (
        <button
          className="bttn bttn_primary h5"
          type="button"
          onClick={e => onClick(e, prevAction)}>
          Назад
        </button>
      )}

      <button
        className={`bttn bttn_${isSaveButtonDisabled ? 'disabled' : 'primary'} h5`}
        disabled={isSaveButtonDisabled}
        type="button"
        onClick={e => onClick(e, nextAction)}>
        {confirmation ? 'Подтвердить' : 'Далее'}
      </button>
    </div>
  );
});

export default StepsButtonsWrapper;
