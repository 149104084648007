import React, { useEffect, useState, useRef, forwardRef } from 'react';

import SelectWrapper from 'components/helpers/SelectWrapper';
import StepsButtonsWrapper from 'components/formSteps/ButtonsWrapper';
import StepsHeaderInfo from 'components/formSteps/HeaderInfo';
import CinemasMap from 'components/formSteps/CinemasMap';

import { fetchListItemsForSelect, convertArrayToSelectView } from 'functions/Data';

import { useDispatch, useSelector } from 'react-redux';
import { updateApplicantFormData } from 'redux/actions/applicantData';

import { apiUrls, apiWrapper } from 'config/routes';

import IconClose from 'icon_close.svg';

// Компонент вывода первого шага заполнения онлайн-заявки
const StepOne = forwardRef(({ step, nextStep, isLoading, setIsLoading }, ref) => {
  const dispatch = useDispatch();
  const applicantFormData = useSelector(state => state.applicantFormData);
  const selectingCityRef = useRef(null);

  const [cities, setCities] = useState([]);
  const [cinemas, setCinemas] = useState([]);
  const [metroStations, setMetroStations] = useState([]);

  const [city, setCity] = useState(applicantFormData.city || {});
  const [cinema, setCinema] = useState(applicantFormData.cinema || '');
  const [metroStation, setMetroStation] = useState(applicantFormData.metroStation || '');

  const [selectingCity, setSelectingCity] = useState(false);

  const firstStepLabel = (
    <span className="row align_items_center clr_grey h2 mb_3">
      <span className="map_point_icon material_icons clr_primary">location_on</span>
      Твой город
      {cities.length > 0 && (
        <div aria-hidden="true"
             className="cursor_pointer text_underline clr_primary ml_2"
             role="button"
             onClick={() => setSelectingCity(true)}>
          {city.name}
        </div>
      )}
    </span>
  );

  // Получение списка городов
  useEffect(() => {
    fetchCities();
  }, []);

  // Получение списка станций метро для селекта
  useEffect(() => {
    if (Object.keys(city).length > 0) {
      setIsLoading(true);

      fetchListItemsForSelect(apiUrls.applicants.metroStations, 'metro_stations', { city_id: city.id })
        .then((response) => {
          setMetroStations(response);
          setIsLoading(false);
        });
    }
  }, [city]);

  // Получение списка кинотеатров для селекта
  useEffect(() => {
    if (Object.keys(city).length > 0) {
      setIsLoading(true);

      fetchListItemsForSelect(apiUrls.applicants.cinemas, 'cinemas',
        { metro_station_id: metroStation.id, city_id: city.id })
        .then((response) => {
          setCinemas(response);
          setIsLoading(false);
        });
    }
  }, [metroStation, city]);

  // Запрос на получение списка городов
  async function fetchCities() {
    setIsLoading(true);

    const response = await apiWrapper.get(apiUrls.applicants.cities);

    if (response.ok) {
      const citiesArray = convertArrayToSelectView(response.data.cities);
      setCities(citiesArray);

      if (Object.keys(city).length < 1) setCity(citiesArray[0]);
    }

    setIsLoading(false);
  }

  // Выбор города
  function handleSelectingCity(id) {
    setCity(cities.find(item => item.id === id));
    setMetroStation('');
    setCinema('');
  }

  // Выбор станции метро
  function handleSelectingMetroStations(option) {
    setMetroStation(option);
    setCinema('');
  }

  // Выбор кинотеатра на карте
  function handleSelectingCinemaOnMap(id) {
    setCinema(cinemas.find(item => item.id === id));
    setMetroStation('');
  }

  // Сохранение выбранных города, метро и кинотеатра первого шага
  function updateReduxApplicantData(e) {
    e.preventDefault();
    e.stopPropagation();

    dispatch(updateApplicantFormData({
      cinema, metroStation, city,
    }));
    nextStep();
  }

  // Закрытие модалки выбора города по клику вне ее области
  function closeSelectingCityModal(e) {
    if (selectingCityRef.current && !selectingCityRef.current.contains(e.target)) setSelectingCity(false);
  }

  // Проверка блокировки кнопки сохранения опросов
  function isSaveButtonDisabled() {
    return Object.keys(cinema).length < 1 || isLoading;
  }

  // Вывод окна выбора города
  function renderSelectingCity() {
    return (
      selectingCity && (
        <div className="modal_wrapper"
             onClick={closeSelectingCityModal}>
          <div className="panel_block with_shadow" ref={selectingCityRef}>
            <div aria-hidden="true"
                 className="bttn_close"
                 onClick={() => setSelectingCity(false)}>
              <img alt="icon" src={IconClose} />
            </div>
            <div className="row align_items_center h2 mb_5">
              <span className="material_icons clr_primary font_big">location_on</span>
              <p className="clr_grey">Выбери свой город</p>
            </div>
            <div className="row">
              {cities.map(item => renderCity(item))}
            </div>
          </div>
        </div>
      )
    );
  }

  // Вывод элемента списка городов
  function renderCity({ id, name }) {
    return (
      <div aria-hidden="true"
           className="form_check d_flex align_items_center mb_3 block_md_6 block_xl_3"
           key={id}
           onClick={() => handleSelectingCity(id)}>
        <input checked={city.id === id}
               type="checkbox"
               readOnly />
        <div className="viewed_input radio"><span /></div>

        <span className="h5 m_0 clr_dark">{name}</span>
      </div>
    );
  }

  // Вывод обертки селектов
  function renderSelects() {
    return (
      <div className="row mb_3">
        <div className="block_xs_12 block_md_6 mr_3 mb_2">
          <SelectWrapper handler={option => handleSelectingMetroStations(option)}
                         options={metroStations}
                         placeholder="Выбор станции метро"
                         value={metroStation} />
        </div>
        <div className="block_xs_12 block_md_6">
          <SelectWrapper handler={option => setCinema(option)}
                         options={cinemas}
                         placeholder="Выбор кинотеатра"
                         value={cinema} />
        </div>
      </div>
    );
  }

  // Вывод контента страницы
  function renderMainContent() {
    return (
      <>
        <StepsHeaderInfo description="Выбери кинотеатр, в котором тебе будет удобно работать."
                         firstStepLabel={firstStepLabel}
                         step={step} />

        {renderSelects()}

        <CinemasMap cinemas={cinemas}
                    selectedCinema={cinema}
                    setCinema={id => handleSelectingCinemaOnMap(id)} />

        <StepsButtonsWrapper isSaveButtonDisabled={isSaveButtonDisabled()}
                             nextAction={updateReduxApplicantData}
                             prevButtonVisible={false}
                             ref={ref} />

        {renderSelectingCity()}
      </>
    );
  }

  return renderMainContent();
});

export default StepOne;
