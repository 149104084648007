import React from 'react';

// Компонент троббера
export default function LoaderThrobber({ type = 'throbber_over', lighter = false }) {
  return (
    <div className={`d_flex justify_content_center align_items_center ${type} ${lighter && 'lighter'}`}>
      <div className="spinner_border" />
    </div>
  );
}
