import React from 'react';

// Функция обертки для блоков основного контента
export default function MainContentWrapper({ children }) {
  return (
    <div className="main_content_wrapper">
      <div className="container">
        {children}
      </div>
    </div>
  );
}
