import React, { useEffect, useState, forwardRef } from 'react';

import StepsButtonsWrapper from 'components/formSteps/ButtonsWrapper';
import StepsHeaderInfo from 'components/formSteps/HeaderInfo';
import FormInputWrapper from 'components/helpers/FormInputWrapper';

import { convertArrayToSelectView } from 'functions/Data';

import { useDispatch, useSelector } from 'react-redux';
import { updateApplicantFormData } from 'redux/actions/applicantData';

import { apiUrls, apiWrapper } from 'config/routes';

// Компонент вывода третьего шага заполнения онлайн-заявки
const StepThree = forwardRef(({ step, nextStep, prevStep, isLoading, setIsLoading }, ref) => {
  const dispatch = useDispatch();
  const applicantFormData = useSelector(state => state.applicantFormData);

  const [citizenships, setCitizenships] = useState([]);

  const [citizenship, setCitizenship] = useState(applicantFormData.citizenship || {});
  const [about, setAbout] = useState(applicantFormData.about || '');

  useEffect(() => {
    fetchCitizenships();
  }, []);

  // Запрос на получение списка гражданств
  async function fetchCitizenships() {
    setIsLoading(true);

    const response = await apiWrapper.get(apiUrls.applicants.citizenships);

    if (response.ok) setCitizenships(convertArrayToSelectView(response.data.citizenships));

    setIsLoading(false);
  }

  // Сохранение гражданства пользователя и информации, откуда он узнал о вакансии
  function updateReduxApplicantData(e) {
    e.preventDefault();
    e.stopPropagation();

    dispatch(updateApplicantFormData({ citizenship, about }));
    nextStep();
  }

  // Проверка блокировки кнопки перехода на следующий этап
  function isSaveButtonDisabled() {
    return Object.keys(citizenship).length < 1 || isLoading;
  }

  // Вывод элемента списка гражданств
  function renderCitizenship({ id, name }) {
    return (
      <div aria-hidden="true"
           className="form_check d_flex align_items_center mb_3 block_md_6 block_lg_4"
           key={id}
           onClick={() => setCitizenship(citizenships.find(item => item.id === id))}>
        <input checked={citizenship.id === id}
               type="checkbox"
               readOnly />
        <div className="viewed_input radio"><span /></div>

        <span className="clr_dark h5 m_0">{name}</span>
      </div>
    );
  }

  // Вывод обертки формы заполнения гражданства и источника информации о вакансии
  function renderFormWrapper() {
    return (
      <div className="row justify_content_center">
        <div className="block_md_7">
          <span className="row clr_grey mb_3">
            <p className="mr_1">Гражданство</p>
            <span className="clr_primary">*</span>
          </span>

          <div className="row mb_4">
            {citizenships.map(item => (renderCitizenship(item)))}
          </div>

          <FormInputWrapper className="mb_4"
                            htmlFor="about"
                            label="Откуда узнал о вакансии"
                            setValue={setAbout}
                            type="textarea"
                            value={about} />
        </div>
      </div>
    );
  }

  // Вывод контента страницы
  function renderMainContent() {
    return (
      <>
        <StepsHeaderInfo description="Укажи свое гражданство и расскажи, откуда ты узнал о вакансии"
                         label="Личная информация"
                         step={step} />

        {renderFormWrapper()}

        <StepsButtonsWrapper isSaveButtonDisabled={isSaveButtonDisabled()}
                             nextAction={updateReduxApplicantData}
                             prevAction={prevStep}
                             ref={ref} />
      </>
    );
  }

  return renderMainContent();
});

export default StepThree;
