import React, { useState, useEffect } from 'react';

import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import { TelegramShareButton, TwitterShareButton, VKShareButton,
  WhatsappShareButton, TelegramIcon, TwitterIcon, VKIcon, WhatsappIcon } from 'react-share';

import LayoutWrapper from 'components/helpers/LayoutWrapper';
import MainContentWrapper from 'components/helpers/MainContentWrapper';

import { apiWrapper, apiUrls, navigationUrls } from 'config/routes.js';

import greeting from 'greeting.png';
import logoDark from 'logo_black.svg';
import nextArrow from 'icon_right.svg';
import prevArrow from 'icon_left.svg';

// Компонент главной страницы
export default function HomePage() {
  const [vacancies, setVacancies] = useState([]);
  const [share, setShare] = useState(false);

  useEffect(() => {
    fetchVacancies();
  }, []);

  // Получение вакансий
  async function fetchVacancies() {
    const response = await apiWrapper.get(apiUrls.applicants.vacancies);
    if (response.ok) {
      setVacancies(response.data.vacancies);
    }
  }

  // Кнопка слайдера вправо
  function RenderNextArrow({ className, style, onClick }) {
    return (
      <button className={className}
              style={{ ...style }}
              type="button"
              onClick={onClick}>
        <img alt="arrow next" src={nextArrow} />
      </button>
    );
  }

  // Кнопка слайдера влево
  function RenderPrevArrow({ className, style, onClick }) {
    return (
      <button className={className}
              style={{ ...style }}
              type="button"
              onClick={onClick}>
        <img alt="arrow previous" src={prevArrow} />
      </button>
    );
  }

  // Рендер Слайдера
  function renderVacanciesSlider() {
    const settings = {
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <RenderNextArrow />,
      prevArrow: <RenderPrevArrow />,
    };

    return (
      <Slider {...settings}>
        {vacancies.map(({ id, name, description }) => (
          <div key={id}>
            <p className="text_center h1 clr_primary text_transform_uppercase pt_5">
              {name}
            </p>
            <p className="vacancy_description text_center h2 pt_4">
              {description}
            </p>
          </div>
        ))}
      </Slider>
    );
  }

  // рендер блока вакансий
  function renderVacancies() {
    return (
      <div className="panel_block vacancies d_flex direction_column align_items_center pt_5 pb_5">
        <p className="text_center h3">У нас открыты вакансии</p>
        <div className="w_100 pb_4">
          {renderVacanciesSlider()}
        </div>

        <NavLink className="vacancy_button bttn bttn_primary h4 mt_3 pl_5 pr_5"
                 onClick={() => window.scrollTo(0, 0)}
                 to={navigationUrls.applicants.formSteps}
                 type="button">
          Присоединяйся
        </NavLink>

        <div className="w_100 row justify_content_center mt_4">
          {share
            ? (
              renderShareBlock()
            ) : (
              <div onClick={() => setShare(!share)}
                   className="block_xs_12 d_flex align_items_center justify_content_center cursor_pointer h4
                              text_transform_uppercase clr_link">
                <span className="material_icons pr_2">share</span>
                <p className="text_underline">Поделиться</p>
              </div>
            )}
        </div>
      </div>
    );
  }

  // Вывод блока с кнопками
  function renderShareBlock() {
    const url = 'http://hr.karofilm.ru';

    return (
      <div className="block_xs_12 block_md_6 block_lg_4">
        <div className="panel_block p_2 pt_3 d_flex justify_content_between
                                align_items_center flex_wrap_wrap box_shadow">
          <TelegramShareButton url={url}><TelegramIcon size={35} round /></TelegramShareButton>
          <TwitterShareButton url={url}><TwitterIcon size={35} round /></TwitterShareButton>
          <VKShareButton url={url}><VKIcon size={35} round /></VKShareButton>
          <WhatsappShareButton url={url}><WhatsappIcon size={35} round /></WhatsappShareButton>
        </div>
      </div>
    );
  }

  // Рендер приветствия
  function renderGreeting() {
    return (
      <div className="greeting clr_dark">
        <div className="container pt_5">
          <div className="inner d_flex direction_column align_items_center">
            <p className="greeting_appeal h1 w_bold m_1 text_transform_uppercase">
              Ты молод, энергичен и&nbsp;любишь кино
            </p>

            <img alt="popcorn"
                 className="greeting_image py_5"
                 src={greeting} />

            <p className="greeting_text h4 pb_5">
              Заполни онлайн-заявку и приходи работать и строить карьеру в
              <img alt="логотип" className="pl_4 pr_4 pt_3 logo_dark" src={logoDark} />
            </p>

            <NavLink className="greeting_button bttn_primary h5 mb_5 b_0 border_r_20 text_transform_uppercase"
                     onClick={() => window.scrollTo(0, 0)}
                     to={navigationUrls.applicants.formSteps}
                     type="button">
              Заполнить заявку
            </NavLink>
          </div>
        </div>
      </div>
    );
  }

  // Рендер блока с тремя шагами трудоустройства
  function renderSteps() {
    return (
      <div className="steps">
        <div className="container d_flex direction_column align_items_center clr_dark pt_5 pb_5">
          <p className="steps_title text_center h2 pb_5">
            Как стать частью нашей команды?
          </p>

          <div className="steps_grid text_center pt_5">
            <span className="steps_square gr_c_3_4">1</span>
            <p className="steps_text gr_c_2_5">
              Выбери кинотеатр, в котором тебе будет удобно работать.
            </p>

            <div className="steps_line gr_c_4_7 bcg_clr_grey" />

            <span className="steps_square gr_c_7_8">2</span>
            <p className="steps_text gr_c_6_9">
              Заполни онлайн-заявку. Если заявка будет принята - с тобой свяжутся
              сотрудники кинотеатра и пригласят на собеседование.
            </p>

            <div className="steps_line gr_c_8_11 bcg_clr_grey" />

            <span className="steps_square gr_c_11_12">3</span>
            <p className="steps_text gr_c_10_13">
              При успешном прохождении собеседования, руководство кинотеатра
              сможет предложить тебе стать частью нашей команды!
            </p>
          </div>
        </div>
      </div>
    );
  }

  // Рендер главной страницы
  function renderMainPage() {
    return (
      <LayoutWrapper>
        {renderGreeting()}
        {renderSteps()}
        <MainContentWrapper>
          {vacancies.length > 0 && renderVacancies()}
        </MainContentWrapper>
      </LayoutWrapper>
    );
  }

  return renderMainPage();
}
