import React, { useEffect, useState, forwardRef } from 'react';

import StepsButtonsWrapper from 'components/formSteps/ButtonsWrapper';
import StepsHeaderInfo from 'components/formSteps/HeaderInfo';

import { useDispatch, useSelector } from 'react-redux';
import { updateApplicantFormData } from 'redux/actions/applicantData';

// Компонент вывода четвертого шага заполнения онлайн-заявки
const StepFour = forwardRef(({ step, nextStep, prevStep }, ref) => {
  const dispatch = useDispatch();
  const applicantFormData = useSelector(state => state.applicantFormData);

  const [schedules, setSchedules] = useState([]);
  const [selectedSchedulesIds, setSelectedSchedulesIds] = useState([]);

  useEffect(() => {
    getSchedules();
  }, []);

  // Заполнение массива графика работ
  function getSchedules() {
    const array = [
      { id: 1, name: 'Полная рабочая неделя' },
      { id: 2, name: 'Только дневные смены' },
      { id: 3, name: 'Только вечерние смены' },
      { id: 4, name: 'Только выходные дни' },
      { id: 5, name: 'Любые возможности' },
    ];

    if (applicantFormData?.schedules) {
      setSelectedSchedulesIds(applicantFormData.schedules.map(item => item.id));
    }

    setSchedules([...array]);
  }

  // Сохранение данных о выбранных вариантах графика работы
  function updateReduxApplicantData(e) {
    e.preventDefault();
    e.stopPropagation();

    dispatch(updateApplicantFormData({ schedules: schedules.filter(item => selectedSchedulesIds.includes(item.id)) }));
    nextStep();
  }

  // Выбор вариантов ответов
  function handleOptions(id) {
    let newSelectedIds = [...selectedSchedulesIds];

    // Ищем индекс выбранного варианта
    const index = newSelectedIds.findIndex(item => item === id);
    const selectAllId = schedules[4].id;

    if (newSelectedIds.includes(id)) newSelectedIds.splice(index, 1);
    else newSelectedIds.push(id);

    // Если вариантов больше 3 и последний вариант ответа не выбран, то выделяются все варианты
    if ((newSelectedIds.length > 3) && !newSelectedIds.includes(selectAllId)) {
      newSelectedIds = schedules.map(item => item.id);
    } else {
      // Когда все элементы выбраны, то при клике не на последний элемент, с него снимается галочка выбора
      newSelectedIds = newSelectedIds.filter(item => item !== selectAllId);
    }

    // При клике на последний элемент выделяем/убраем галочки у всех элементов
    // Проверяется наличие последнего элемента в массиве выбранных и в зависимости от этого
    // ставится противоположное значение всем вариантам ответов
    if (id === 5) {
      newSelectedIds = newSelectedIds.includes(selectAllId) ? [] : schedules.map(item => item.id);
    }

    setSelectedSchedulesIds(newSelectedIds);
  }

  // Проверка блокировки кнопки перехода на следующий этап
  function isSaveButtonDisabled() {
    return selectedSchedulesIds.length < 1;
  }

  // Вывод элемента списка графиков работы
  function renderSchedule({ id, name }) {
    return (
      <div aria-hidden="true"
           className="form_check d_flex align_items_center mb_3 block_md_12"
           key={id}
           onClick={() => handleOptions(id)}>
        <input checked={selectedSchedulesIds.includes(id)}
               type="checkbox"
               readOnly />
        <div className="viewed_input"><span /></div>

        <span className="h5 m_0 clr_dark" style={{ maxWidth: '90%' }}>{name}</span>
      </div>
    );
  }

  // Вывод обертки формы заполнения желаемого графика работы
  function renderFormWrapper() {
    return (
      <div className="d_flex align_items_center direction_column">
        <div>
          <span className="row clr_grey mb_3">
            <p className="mr_1">График работы</p>
            <span className="clr_primary">*</span>
          </span>

          <div className="mb_4">
            {schedules.length > 0 && schedules.map(item => (renderSchedule(item)))}
          </div>
        </div>
      </div>
    );
  }

  // Вывод контента страницы
  function renderMainContent() {
    return (
      <>
        <StepsHeaderInfo description="Выбери желаемые варианты графика работы"
                         label="График работы"
                         step={step} />

        {renderFormWrapper()}

        <StepsButtonsWrapper isSaveButtonDisabled={isSaveButtonDisabled()}
                             nextAction={updateReduxApplicantData}
                             prevAction={prevStep}
                             ref={ref} />
      </>
    );
  }

  return renderMainContent();
});

export default StepFour;
