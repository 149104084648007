import React from 'react';

import { isBrowser, isMobile } from 'react-device-detect';

import { findEnumItem } from 'functions/Enums';

import { useSelector } from 'react-redux';

import IconMovie from 'icon_movie.svg';

// Вывод шапки формы заполнения онлайн-заявки
export default function StepsHeaderInfo({ step, firstStepLabel, description, label, isTest = false }) {
  const applicantFormData = useSelector(state => state.applicantFormData);
  const { progress, afterProgress } = findEnumItem('steps', step);

  // Вывод процентажа возможности стать частью команды
  function renderPercents() {
    const percents = parseInt(afterProgress, 10) - parseInt(progress, 10);

    return (
      <div className="block_md_4 pr_5">
        <div className="d_flex align_items_center panel_progress mb_2">
          <p className="m_0 mr_2 clr_primary text_info">+{percents}%</p>
          <div className={`progress_bar ${isBrowser && 'big'}`}>
            <span className="progress_bar_fill" style={{ width: progress }} />
            <span className="progress_bar_after_fill" style={{ width: afterProgress }} />
          </div>
        </div>
        <p className="h6 clr_grey_dark">к возможности стать частью нашей команды!</p>
      </div>
    );
  }

  // Вывод прогрессбара шага заполнения заявки
  function renderSteps() {
    return (
      <div className="block_md_5">
        <div className="row panel_progress">
          <p className="text_info">{isTest ? 'Вопрос' : 'Шаг'} {step} из {isTest ? '3' : '5'}</p>
          <div className="progress_bar">
            <span className="progress_bar_fill" style={{ width: `${(step / (isTest ? 3 : 5)) * 100}%` }} />
          </div>
        </div>
      </div>
    );
  }

  // Вывод контента шапки формы
  function renderMainContent() {
    return (
      <div>
        {(step > 1 && !isTest) && (
          <div className={`row clr_grey mb_${isMobile ? '2' : '4'}`}>
            <img alt="movie" className="mr_2" src={IconMovie} style={{ width: 24 }} />
            <p>{applicantFormData?.cinema?.label}</p>
          </div>
        )}

        <div className={`row mb_${isMobile ? '3' : '5'} ${isTest && 'reverse_direction'}`}>
          <div className="block_md_7">
            {(step === 1 && !isTest) ? (
              firstStepLabel
            ) : (
              <p className={`clr_grey mr_2 ${isMobile ? 'h3' : 'h2'}`}>{label}</p>
            )}
          </div>
          {renderSteps()}
        </div>

        {!isTest
          && (
            <div className={`row mb_${isMobile ? '3' : '5'}`}>
              <div className="block_md_5 clr_grey mb_2 text_info">{description}</div>
              <div className="block_md_2" />
              {renderPercents()}
            </div>
          )}
      </div>
    );
  }

  return renderMainContent();
}
